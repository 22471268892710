@font-face {
    font-family: 'Roboto-Bold';
    src: url('../../assets/font/Roboto-Bold.ttf');
}

html, body {
    max-width: 100% !important;
    overflow-x: hidden !important;
}

.container {
    min-width: 320px;
    /* max-width: 750px; */
}

.content-div{
    font-family: Arial, Helvetica, sans-serif;
}

img {
    width: 24px;
    height: 24px;
}

.reg-body {
    /* min-width: 428px; */
    margin: 0 auto;
    max-width: 430px;
    height: 2380px;
    background-image: url('../../assets/image/registered-member/bgGroupTc.png');
    background-position: 0px 0px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.reg-body[lang=en] {
    background-image: url('../../assets/image/registered-member/bgGroupEn.png');
}

.reg-body {
    position: relative;
}

.logo {
    position: absolute;
    left: 50%;
    top: 16px;
    width: 180px;
    height: 50px;
    margin-left: -90px;
}

h2 {
    position: absolute;
    left: 5.6%;
    top: 3.8%;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 1.4px;
}

h2[lang=en]{
    font-size: 20px;
    line-height: 22px;
}

.vip-btn {
    position: absolute;
    left: 48%;
    top: 141px;
    width: 192px;
    height: 56px;
}

.m-upgrade-content{
    position: absolute;
    left: 51%;
    top: 385px;
    width: 190px;
    font-size: 14px;
    color: black;
}

.b-upgrade-title{
    position: absolute;
    left: 5.6%;
    top: 23.5%;
    width: 220px;
    letter-spacing: -1px;
    text-align: center;
}

.b-upgrade-content{
    position: absolute;
    left: 5.6%;
    top: 625px;
    width: 220px;
    text-align: center;
    font-size: 14px;
    color: black;
}

.more-title{
    position: absolute;
    left: 42.9%;
    top: 30%;
    width: 220px;
    letter-spacing: -1px;
    text-align: center;
}

.more-content{
    position: absolute;
    left: 45%;
    top: 780px;
    color: black;
    text-align: center;
    font-size: 14px;
    width: 220px;
    letter-spacing: -1px;
}

.title {
    position: absolute;
    left: 5.6%;
    top: 37%;
    width: 220px;
    letter-spacing: -1px;
    text-align: center;
}

.content {
    position: absolute;
    left: 5.6%;
    top: 950px;
    width: 220px;
    text-align: center;
    font-size: 14px;
    /* white-space: wrap; */
    color: black;
}

/* .remark {
    position: absolute;
    left: 5.6%;
    top: 384px;
    font-size: 11px;
    letter-spacing: 0.42px;
    color: rgb(118 118 118);
    width: 220px;
    text-align: center;
} */

.con-title {
    position: absolute;
    left: 41%;
    top: 42.9%;
    color: black;
    text-align: center;
    width: 250px;
    letter-spacing: -1px;
}

.con-content {
    position: absolute;
    left: 41%;
    top: 1081px;
    width: 250px;
    text-align: center;
    font-size: 14px;
    color: black;
}

.con-content[lang=en] {
    top: 1073px;
}

.inconNav, .icon-close{
    cursor: pointer;
    margin-left: 10px;
}


.inconNav,
.iconNeumorphismArrowRight,
.iconGamificationCoin {
    vertical-align: middle;
    width: 16px;
    height: 16px;
}

.cale {
    position: absolute;
    left: 41%;
    top: 1135px;
    font-size: 14px;
    color: rgb(118 118 118);
    text-align: center;
    width: 250px;
}

.three {
    position: absolute;
    left: 50%;
    top: 1161px;
    font-size: 14px;
    color: black;
}

.three[lang=en] {
    left: 45%;
}

.five {
    position: absolute;
    left: 50%;
    top: 1188px;
    font-size: 14px;
    color: black;
}

.five[lang=en] {
    top: 1196px;
    left: 45%;
}

.extra-title {
    position: absolute;
    left: 5.6%;
    top: 52.3%;
    color: black;
    width: 220px;
    text-align: center;
    letter-spacing: -1px;
}

.extra-content {
    position: absolute;
    left: 5.6%;
    top: 1323px;
    width: 230px;
    text-align: center;
    font-size: 14px;
    white-space: wrap;
    color: black;
}

.extra-remark {
    position: absolute;
    left: 5.6%;
    top: 1380px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    white-space: wrap;
    color: black;
    width: 230px;
}


/* 生日 */

.birthday-title {
    position: absolute;
    left: 40%;
    top: 60.7%;
    color: black;
    text-align: center;
    width: 250px;
    letter-spacing: -1px;
}

.birthday-content {
    position: absolute;
    left: 40%;
    top: 1513px;
    width: 250px;
    height: 42px;
    font-size: 14px;
    color: black;
    text-align: center;
}

.share-title {
    position: absolute;
    left: 5.6%;
    top: 67%;
    width: 230px;
    font-size: 26px;
    color: black;
    text-align: center;
    letter-spacing: -1px;
}

.share-content {
    position: absolute;
    left: 5.6%;
    top: 1660px;
    width: 230px;
    height: 42px;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    color: black;
}


/* 登记 */

.register {
    position: absolute;
    left: 50%;
    top: 1863px;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 1.4px;
    text-align: center;
    width: 230px;
    height: 30px;
    letter-spacing: -1px;
    margin-left: -25%;
}

.left1 {
    position: absolute;
    left: 11.6%;
    top: 1933px;
    width: 56px;
    height: 58px;
    text-align: center;
    line-height: 58px;
    font-size: 50px;
}

.num1 {
    color: rgb(72 33 0);
}

.num0 {
    color: rgb(253 179 8);
}

.right1-title {
    position: absolute;
    left: 50%;
    top: 81%;
    width: 230px;
    font-size: 26px;
    text-align: center;
    color: black;
    letter-spacing: -1px;
    margin-left: -25%;
    font-family: 'Roboto-Bold';
}

.right1-content {
    position: absolute;
    left: 50%;
    top: 83.8%;
    width: 220px;
    height: 63px;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    color: black;
    margin-left: -25%;
}

.left2 {
    position: absolute;
    left: 11.6%;
    top: 2104px;
    width: 56px;
    height: 58px;
    text-align: center;
    line-height: 58px;
    font-size: 50px;
}

.num1-2 {
    color: rgb(72 33 0);
}

.num0-2 {
    color: rgb(253 179 8);
}

.right2-title {
    position: absolute;
    left: 50%;
    top: 87.9%;
    width: 220px;
    font-size: 26px;
    margin-left: -24%;
    text-align: center;
    letter-spacing: -1px;
    color: black;
    font-family: 'Roboto-Bold';
}

.right2-content {
    position: absolute;
    left: 50%;
    top: 90.4%;
    width: 220px;
    height: 63px;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    margin-left: -25%;
    color: black;
}

.left3 {
    position: absolute;
    left: 11.6%;
    top: 2275px;
    width: 56px;
    height: 58px;
    text-align: center;
    line-height: 58px;
    font-size: 50px;
}

.num1-3 {
    color: rgb(72 33 0);
}

.num0-3 {
    color: rgb(253 179 8);
}

.top-title {
    position: absolute;
    font-size: 22px;
    text-align: center;
    color: black;
    letter-spacing: -1px;
    font-family: 'Roboto-Bold';
    top: 2.3%;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
}

.top-title[lang=en] {
    top: 2.5%;
}

.right3-title {
    position: absolute;
    left: 50%;
    top: 94.5%;
    width: 220px;
    font-size: 26px;
    letter-spacing: 1.4px;
    text-align: center;
    margin-left: -25%;
    color: black;
    letter-spacing: -1px;
    font-family: 'Roboto-Bold';
}

.right3-content {
    position: absolute;
    left: 50%;
    top: 97%;
    width: 220px;
    height: 63px;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    margin-left: -25%;
    color: black;
}


/* footer底部 */

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.footer a{
    text-decoration: none;
    color: inherit;
}

.vip-footer-btn{
    display: block;
    min-width: 260px;
    height: 56px;
    margin-bottom: 10px;
    padding: 0 5px;
}

/* 弹出框 */
.con-wrapper {
    position: absolute;
    width: 100%;
    height: 2555px;
    background-color: rgba(0,0,0,0.5);
    z-index: 998;
}
.icon-close {
    position: absolute;
    left: 90%;
    top: 5%;
    width: 16px;
    height: 16px;
    z-index: 99;
}
.wrapper-body {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 380px;
    /* height: 460px; */
    background-color: #fff;
    text-align: center;
    border-radius: 10px;
    z-index: 999;
}
.head-img {
    width: 100px;
    height: 100px;
    margin-top: 32px;
}
.con-head {
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 1.4px;
    margin:5px 0 0 8px;
}
.wrapper-content {
    width: 348px;
    font-size: 18px;
    line-height: 29px;
    text-align: center;
    padding: 8px 16px;
    color: rgba(0,0,0,0.5);
}
.top {
    margin-bottom: 20px;
}
.wrapper-footer {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 24px;
    color: rgba(0,0,0,0.5);
}

.action-button{
    font-size: 20px;
    line-height: 56px;
    text-align: center;
    color: #fff;
    background-color: #482100;
    border-radius: 10px;
}

.action-button[lang=en]{
    font-size: 16px;
}

.activation-button{
    font-size: 20px;
    line-height: 56px;
    text-align: center;
    color: #000;
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 10px;
}

.activation-button[lang=en]{
    font-size: 16px;
}

.octopus-icon{
    vertical-align: middle;
    width: 80px;
    height: 38px;
}